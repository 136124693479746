@import url("https://fonts.googleapis.com/css2?family=Alata&family=Poppins&family=Righteous&family=Roboto&family=Pacifico&family=Arimo:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@1,300&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400&display=swap'); */
/* @import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap"); */
/* font-family: 'Titillium Web', sans-serif; */
/* font-family: 'Hind Madurai', sans-serif; */
/* font-family: 'Nunito Sans', sans-serif; */

:root {
  --color-green: #6ac045;
  --color-title: #6ac045;
  --color-primary-link: #d4d4d4;
  --color-dark-link: #bebebe;
  --bg-dark: #1d1d1d;
  --bg-black: #000;
  --bg-primary: #663399;
  --bg-light-green: #6ac045;
  --bg-green: #2e9b00;
  --navbar-height: 64px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: var(--bg-dark);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--bg-dark);
}

::-webkit-scrollbar-thumb {
  background: rgb(99, 99, 99);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(163, 163, 163);
}

::-webkit-scrollbar-corner {
  background-color: var(--bg-dark);
}
